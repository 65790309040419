﻿import * as $ from "jquery";
import tumGlobal from "./global.js";
import { getCookie, setCookie } from "./global/cookie";
import { addEventDelegate } from "./global/dom";
import { searchWidgets } from "./navbar/searchSelect.js";

var searchLinks = {
	options: {
	},
	init: function () {
		searchLinks.initHandlers();
		searchLinks.setCookie();
	},
	initHandlers: function () {
		addEventDelegate(document, 'click', '.back', (element, e) => {
			e.preventDefault();
			window.history.back();
		});

		addEventDelegate(document, 'click', '.search-tu, .search-ctu, .search-address, .search-contact', (element, e) => {
			e.preventDefault();
			searchLinks.searchLink($(element));
		});
		
		addEventDelegate(document, 'click', '.redirect-catalog', element => {
			var href = $(element).attr('href');
			if (href) {
				location.href = href;
			}
		});
	},
	searchLink: function (el) {
		tumGlobal.scrollUp();
		var searchType = $(el).attr('data-code');

		let searchTypeCode = {
			'tu': 'byGoods',
			'ctu': 'byOrganization',
			'address': 'byAddress',
			'contact': 'byContacts'
		}[searchType];

		if (tumGlobal.isMob()) {
			import('./searchMenu.js').then(({tum_searchMenu}) => {
				tum_searchMenu.toggleMenu('show')
				.then(() => {
					tum_searchMenu.setSearchType(searchTypeCode);
					tum_searchMenu.focusSearch();
				});
			});
		} else {
			searchWidgets.main.setSearchType(searchTypeCode);
			searchWidgets.main.focus();
		}
	},
	setCookie: function () {
		if (getCookie("tum-searchLinks-isViewed") != "1") {
			setCookie("tum-searchLinks-isViewed", "1", 30);
		}
	}
};

export default searchLinks;