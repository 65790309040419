import "./layout.js";
import tumGlobal from "../global.js";
import searchLinks from "../searchLinks.js";

//css
import { addEventDelegate } from "../global/dom";
import { searchWidgets } from "../navbar/searchSelect.js";

window.addEventListener('load', () => {
    searchLinks.init();

    addEventDelegate(document, 'click', '.search-btn', (element, e) => {
        e.preventDefault();
        if (!tumGlobal.isMob()) {
            searchWidgets.main.focus();
        } else {
            import("../searchMenu").then(({ tum_searchMenu }) => {
                tum_searchMenu.toggleMenu().then(() => tum_searchMenu.focusSearch());
            });
        }
    });
});
